import React from 'react'
import Helmet from 'react-helmet'

import '../less/style.less'

import Header from '../components/header'
import Contact from '../components/contact'
import Footer from '../components/footer'

export default () => (
    <div>
        <Helmet title="Privacy Policy - techrec">
            <html lang="en" />

            <meta name="description" content="We believe that people are a company’s greatest asset. That's why we help tech companies build high performing, diverse tech teams." />

            <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/images/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#2c3956" />
            <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:url" content="https://www.techrec.cc" />
            <meta property="og:title" content="We build tech teams." />
            <meta property="og:description" content="It’s as simple as that. We help tech companies build high performing, diverse teams." />
            <meta property="og:image" content="/images/techrec-team.svg" />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content="TechRecIT" />
            <meta name="twitter:title" content="We build tech teams." />
            <meta name="twitter:description" content="It’s as simple as that. We help tech companies build high performing, diverse teams." />
            <meta name="twitter:image" content="/images/techrec-team.svg" />

            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />
        </Helmet>

        <Header
            size="half"
            background=""
            heading="Privacy Policy"
            content=""
            buttons={false}
        />

        <section className="policy">
            <div className="wrapper">
                <div className="text-wrapper">
                    <p>techrec (”We”) are committed to protecting and respecting your privacy.</p>

                    <p>Our Group means our subsidiaries, our ultimate holding company and its subsidiaries, our associated companies as defined in section 1159 of the UK Companies Act 2006 (our Group)</p>
                    <p>This notice sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.</p>
                    <p>The General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) is a new regulation which replaces the Data Protection Regulation (Directive 95/46/EC) The Regulation aims to harmonise data protection legislation across EU member states, enhancing privacy rights for individuals and providing a strict framework within which commercial organisations can legally operate.</p>
                    <p>Even though the UK has expressed its intention to leave the EU in March 2019, the GDPR will be applicable in the UK from 25th May 2018. The Government intends for the GDPR to continue in UK law post Brexit and has also introduced a Data Protection Bill to replace the current Data Protection Act in due course.</p>
                    <p>Your new rights under the GDPR are set out in this notice but will only apply once the GDPR becomes law on 25th May 2018.</p>
                    <p>Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
                    <p>For the purposes of data protection legislation in force from time to time the data controller is Tech Rec IT Limited, company number 08900148</p>
                    <p>Our nominated representative is Tom Wetwood.</p>

                    <h2>Who we are and what we do</h2>
                    <p>We are a recruitment agency and recruitment business as defined in the Employment Agencies and Employment Businesses Regulations 2003 (our business). We collect the personal data of the following types of people to allow us to undertake our business:</p>
                    <ul>
                        <li>Prospective and placed candidates for permanent or temporary roles;</li>
                        <li>Prospective and live client contacts;</li>
                        <li>Supplier contacts to support our services;</li>
                        <li>Employees, consultants, temporary workers;</li>
                        <li>We collect information about you to carry out our core business and ancillary activities.</li>
                        <li>Information you give to us or we collect about you.</li>
                    </ul>
                    <p>This is information about you that you give us by filling in forms on our site www.techrec.cc(our site) or by corresponding with us by phone, e-mail or otherwise. It includes information you provide when you register to use our site, to enter our database, subscribe to our services, attend our events, participate in discussion boards or other social media functions on our site, enter a competition, promotion or survey, and when you report a problem with our site.</p>
                    <p>The information you give us or we collect about you may include your name, address, private and corporate e-mail address and phone number, financial information, compliance documentation and references verifying your qualifications and experience and your right to work in the United Kingdom, curriculum vitae and photograph, links to your professional profiles available in the public domain e.g. LinkedIn, Twitter, GitHub, Xing, Reddit and business Facebook, or corporate website.</p>

                    <h2>Information we collect about you when you visit our website</h2>
                    <p>With regard to each of your visits to our site we will automatically collect the following information:</p>
                    <ul>
                        <li>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information if applicable, browser type and version, browser plug-in types and versions, operating system and platform.</li>
                        <li>information about your visit, including [the full Uniform Resource Locators (URL),] clickstream to, through and from our site (including date and time), products you viewed or searched for’ page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number.</li>
                    </ul>

                    <h2>Information we obtain from other sources</h2>
                    <p>This is information we obtain about you from other sources such as LinkedIn, corporate websites, job board websites, online CV libraries, your business card, personal recommendations. In this case we will inform you, by sending you this privacy notice, within a maximum of 30 days of collecting the data of the fact we hold personal data about you, the source the personal data originates from and whether it came from publicly accessible sources, and for what purpose we intend to retain and process your personal data.</p>
                    <p>We are working closely with third parties including companies within our Group, business partners, sub-contractors in technical, professional, payment and other services, advertising networks, analytics providers, search information providers, credit reference agencies, professional advisors. We may receive information about you from them for the purposes of our recruitment services and ancillary support services.</p>

                    <h2>Purposes of the processing and the legal basis for the processing</h2>
                    <p>We use information held about you in the following ways:</p>
                    <p>To carry out our obligations arising from any contracts we intend to enter into or have entered into between you and us and to provide you with the information, products and services that you request from us or we think will be of interest to you because it is relevant to your career or to your organisation.</p>
                    <p>To provide you with information about other goods and services we offer that are similar to those that you have already purchased, been provided with or enquired about.</p>
                    <p>The core service we offer to our candidates and clients is the introduction of candidates to our clients for the purpose of temporary or permanent engagement.  However, our service expands to supporting individuals throughout their career and to supporting businesses’ resourcing needs and strategies.</p>
                    <p>Our legal basis for the processing of personal data is our legitimate business interests, described in more detail below, although we will also rely on contract, legal obligation and consent for specific uses of data.</p>
                    <p>We will rely on contract if we are negotiating or have entered into a placement agreement with you or your organisation or any other contract to provide services to you or receive services from you or your organisation.</p>
                    <p>We will rely on legal obligation if we are legally required to hold information on to you to fulfil our legal obligations.</p>
                    <p>We will in some circumstances rely on consent for particular uses of your data and you will be asked for your express consent, if legally required.  Examples of when consent may be the lawful basis for processing include permission to introduce you to a client (if you are a candidate).</p>

                    <h2>Our Legitimate Business Interests</h2>
                    <p>Our legitimate interests in collecting and retaining your personal data is described below:</p>
                    <p>As a recruitment business and recruitment agency we introduce candidates to clients for permanent employment, temporary worker placements or independent professional contracts.  The exchange of personal data of our candidates and our client contacts is a fundamental, essential part of this process.</p>
                    <p>In order to support our candidates’ career aspirations and our clients’ resourcing needs we require a database of candidate and client personal data containing historical information as well as current resourcing requirements.</p>
                    <p>To maintain, expand and develop our business we need to record the personal data of prospective candidates and client contacts.</p>

                    <h2>Consent</h2>
                    <p>Should we want or need to rely on consent to lawfully process your data we will request your consent orally, by email or by an online process for the specific activity we require consent for and record your response on our system.  Where consent is the lawful basis for our processing you have the right to withdraw your consent to this particular processing at any time.</p>
                    <p>Other Uses we will make of your data:</p>
                    <ul>
                        <li>Use of our website;</li>
                        <li>to notify you about changes to our service;</li>
                        <li>to ensure that content from our site is presented in the most effective manner for you and for your computer.</li>
                    </ul>
                    <p>We will use this information:</p>
                    <ul>
                        <li>to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                        <li>to improve our site to ensure that content is presented in the most effective manner for you and for your computer;</li>
                        <li>to allow you to participate in interactive features of our service, when you choose to do so;</li>
                        <li>as part of our efforts to keep our site safe and secure;</li>
                        <li>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;</li>
                        <li>to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.</li>
                    </ul>

                    <p>We do not undertake automated decision making or profiling. We do use our computer systems to search and identify personal data in accordance with parameters set by a person. A person will always be involved in the decision making process.</p>

                    <h2>Cookies</h2>
                    <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>

                    <h2>Disclosure of your information inside and outside of the EEA</h2>
                    <p>We will share your personal information with:</p>

                    <ul>
                        <li>Any member of our group both in the EEA and outside of the EEA.</li>
                        <li>Selected third parties including:
                            <ul>
                                <li>clients for the purpose of introducing candidates to them ;</li>
                                <li>candidates for the purpose of arranging interviews and engagements;</li>
                                <li>clients, business partners, suppliers and sub-contractors for the performance and compliance obligations of any contract we enter into with them or you;</li>
                            </ul>
                        </li>
                    </ul>

                    <p>We will disclose your personal information to third parties:</p>
                    <ul>
                        <li>In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets.</li>
                        <li>If techrec or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
                        <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use [Available on request] or terms and conditions of supply of services [Available on request] and other agreements; or to protect the rights, property, or safety of techrec, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
                    </ul>

                    <p>The lawful basis for the third party processing will include:</p>
                    <ul>
                        <li>Their own legitimate business interests in processing your personal data, in most cases to fulfil their internal resourcing needs;</li>
                        <li>satisfaction of their contractual obligations to us as our data processor;</li>
                        <li>for the purpose of a contract in place or in contemplation;</li>
                        <li>to fulfil their legal obligations.</li>
                    </ul>

                    <h2>Where we store and process your personal data</h2>
                    <p>The data that we collect from you may/will be transferred to, and stored at, a destination outside the European Economic Area (”EEA”). It may be transferred to third parties outside of the EEA for the purpose of our recruitment services. It may/will also be processed by staff operating outside the EEA who work for us or for one of our suppliers. This includes staff engaged in, among other things, our recruitment services and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. techrec will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy notice.</p>
                    <p>All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted [using SSL technology]. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                    <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>

                    <h2>Retention of your data</h2>
                    <p>We understand our legal duty to retain accurate data and only retain personal data for as long as we need it for our legitimate business interests and that you are happy for us to do so.  Accordingly, we have a data retention notice and run data routines to remove data that we no longer have a legitimate business interest in maintaining.</p>
                    <p>We do the following to try to ensure our data is accurate:</p>
                    <ul>
                        <li>prior to making an introduction we check that we have accurate information about you</li>
                        <li>we keep in touch with you so you can let us know of changes to your personal data</li>
                        <li>We segregate our data so that we keep different types of data for different time periods.  The criteria we use to determine whether we should retain your personal data includes:
                            <ul>
                                <li>the nature of the personal data;</li>
                                <li>its perceived accuracy;</li>
                                <li>our legal obligations;</li>
                                <li>whether an interview or placement has been arranged; and</li>
                                <li>our recruitment expertise and knowledge of the industry by country, sector and job role.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>We may archive part or all of your personal data or retain it on our financial systems only, deleting all or part of it from our main Customer Relationship Manager (CRM) system. We may pseudonymise parts of your data, particularly following a request for suppression or deletion of your data, to ensure that we do not re-enter your personal data on to our database, unless requested to do so.</p>
                    <p>For your information, Pseudonymised Data is created by taking identifying fields within a database and replacing them with artificial identifiers, or pseudonyms.</p>
                    <p>Our current retention notice is available upon request.</p>

                    <h2>Your rights</h2>
                    <p>You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes and we will collect express consent from you if legally required prior to using your personal data for marketing purposes.</p>
                    <p>You can exercise your right to accept or prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at <a href="mailto:gdpr@techrec.cc">gdpr@techrec.cc</a>.</p>
                    <p>The GDPR provides you with the following rights. To:</p>

                    <ul>
                        <li>Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                        <li>Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</li>
                        <li>Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.</li>
                        <li>Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
                        <li>Request the transfer of your personal information to another party in certain formats, if practicable.</li>
                        <li>Make a complaint to a supervisory body which in the United Kingdom is the Information Commissioner’s Office. The ICO can be contacted through this link: <a href="https://ico.org.uk/concerns">https://ico.org.uk/concerns</a>.</li>
                    </ul>

                    <p>The Data Protection Act 1998 and the GDPR give you the right to access information held about you.  We also encourage you to contact us to ensure your data is accurate and complete.</p>
                    <p>A subject access request should be submitted to <a href="mailto:gdpr@techrec.cc">gdpr@techrec.cc</a>.</p>
                </div>
            </div>
        </section>

        <Contact />

        <Footer />

    </div>
)
