import React from 'react'

import Particles from 'react-particles-js'

export default props => (
    <header className={'size-' + props.size} style={{ backgroundImage: 'url(\'' + props.background + '\')' }}>
        <div className="wrapper">
            <div className="top">
                <div className="site-logo">
                    <a href="/">
                        <img src="/images/techrec-logo.png" alt="the techrec logo" />
                    </a>
                </div>
                <ul>
                    <li><a href="#about">About</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
            <div className="content">
                {props.heading && <h1>{props.heading}</h1>}
                {props.heading && <p>{props.content}</p>}
                {props.buttons &&
                    <div className="buttons">
                        <a href="https://calendly.com/tom-364" className="button">Request a Call</a>
                        <a href="mailto:hi@techrec.cc" className="button">Email Us</a>
                    </div>
                }
            </div>
        </div>
    </header>
)
