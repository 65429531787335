import React from 'react'

export default () => (
    <footer>
        <div className="wrapper">
            <p>&copy; 2019 TechRec</p>
            <ul>
                <li><a href="/#about">About</a></li>
                <li><a href="/#contact">Contact</a></li>
                <li><a href="mailto:hi@techrec.cc">Email Us</a></li>
                <li><a href="https://calendly.com/tom-364" target="_blank">Book a Call</a></li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
        </div>
    </footer>
)
