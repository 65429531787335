import React from 'react'

export default () => (
    <section id="contact" className="contact">
        <div className="wrapper">
            <div className="right-img" style={{ backgroundImage: 'url(\'/images/techrec-team.svg\')' }} role="img" aria-label="a group of people sitting at a desk"></div>
            <div className="text-wrapper">
                <div className="buttons">
                    <a href="https://calendly.com/tom-364" className="button">Request a call</a>
                    <a href="mailto:hi@techrec.cc" className="button">Email Us</a>
                </div>
            </div>
        </div>
    </section>
)
